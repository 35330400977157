import React from "react";
import ReactDOM from "react-dom";
import Root from "views/Root";
import AppProviders from "providers/AppProviders";
import 'assets/styles/fonts.css';
import 'assets/styles/style.css';

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <Root />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
);
