import React, { useEffect, useState } from "react";
import { Button, SelectField, TextField } from "Theme";
import { productService as api, algorithmService } from "../../../_services";
import { useParams } from "react-router-dom";



const ProductEdit = ({ refresh }) => {
  const { id } = useParams();
  const [algorithms, setAlgorithms] = useState([]);

  useEffect(() => {
    algorithmService.index().then((data) => {
      setAlgorithms(data);
    });
  }, [id]);

  const [product, setProduct] = useState(null);

  const onChange = ({ target: { value, name } }) =>
    setProduct({
      ...product,
      [name]: value,
    });

  useEffect(() => {
    api.get(id).then((data) => {
      setProduct(data);
    });
  }, [id]);

  const updateProduct = (e) => {
    e.preventDefault();
    api
      .update(id, product)
      .then((data) => {
        console.log(data);
      })
      .catch(() => {
        alert("Nie udało się dodać");
      });
  };

  return (
    <React.Fragment>
      {product && (
        <form onSubmit={updateProduct}>
          <TextField
            caption="Nazwa produktu"
            name="name"
            value={product.name}
            onChange={onChange}
            error={null}
          />
          <SelectField
            caption="Mechanizm"
            name="algorithm"
            items={[{ id: 0, name: "Wybierz" }, ...algorithms]}
            value={product.algorithm}
            onChange={onChange}
            error={null}
          />
          <TextField
            caption="Url"
            name="url"
            value={product.url}
            onChange={onChange}
            error={null}
          />

          <Button type="submit"> Zapisz</Button>
        </form>
      )}
    </React.Fragment>
  );
};

export default ProductEdit;
