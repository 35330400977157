import React, { useState, useEffect, useCallback,useContext } from "react";
import { Title, Table } from "Theme";
import { productService as api } from "_services";
import { Link } from "react-router-dom";
import { GrEdit,GrRefresh,GrFormTrash,GrView } from "react-icons/gr";
import { ProductContext } from "./context";

const tableHeaders = [
  { title: "Id", prop: "id", width: 70 },
  { title: "Nazwa produktu", prop: "name" },
  { title: "Mechanizm", prop: "algorithm_name" },
  { title: "Cena", prop: "last_price" },
  { title: "Stan", prop: "last_stock" },
  {
    title: "Zarządzanie",
    prop: null,
    render: (rowData) => <Action rowData={rowData} />,
  },
];



const Action = ({ rowData }) => {
  const { refreshData, remove } = useContext(ProductContext);
  return (
  <div Style="display: flex; justify-content: space-between">
    <Link to={`/product/edit/${rowData.id}`}><GrEdit /></Link> 
    <a href="#" onClick={() => refreshData(rowData.id)}><GrRefresh  /></a>
    <a href="#" onClick={() => remove(rowData.id)}><GrFormTrash Style="color:red" /></a>
    <Link to={`/product/view/${rowData.id}`}><GrView /></Link>
  </div>
)
  }

const ProductIndex = () => {
  const [products, setProducts] = useState([]);




  const refresh = useCallback(() => {
    api.index().then((data) => {
      setProducts(data);
    });
  }, []);


  const remove = (id) => {
    if (window.confirm("Skasować produkt?")) {
      api.remove(id).then(() => {
        refresh();
      });
    }
  };

  const refreshData = (id) => {
    api.refresh(id).then(() => {
      refresh();
    });
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <ProductContext.Provider value={{ refresh, remove,refreshData }}>
      <Title>Produkty</Title>
      
      <Table headers={tableHeaders} data={products} />
    </ProductContext.Provider>
  );
};

export default ProductIndex;
