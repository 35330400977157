import React, { useState, useEffect, useCallback } from "react";
import { Title, SubTitle, Table } from "Theme";
import { productService as api } from "_services";
import { useParams } from "react-router-dom";
import { Chart } from "react-google-charts";

const tableHeaders = [
  { title: "Data", prop: "date" },
  { title: "Cena", prop: "price" },
  { title: "Stan", prop: "stock" },
];

export const data = [
  [
    "Dzień",
    "Stan",
    "Cena"
  ],
  [1, 37.8, 80.8],
  [2, 30.9, 69.5],
  [3, 25.4, 57],
  [4, 11.7, 18.8],
  [5, 11.9, 17.6],
];

export const options1 = {
  chart: {
    title: "Wykres zmiany cen"
  },
};

export const options2 = {
  chart: {
    title: "Wykres zmiany stanów"
  },
};

const ProductView = () => {
  const [product, setProduct] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const { id } = useParams();

  const refresh = useCallback(() => {
    api.get(id).then((data) => {
      setProduct(data);
      calculateData(data.data);
    });
  }, [id]);

  useEffect(() => {
    refresh();
  }, [refresh, id]);

  const calculateData = (data) => {
      const result1 = [];
      const result2 = [];
      result1.push([
        "Dzień",
        "Stan",
      ]);

      result2.push([
        "Dzień",
        "Cena",
      ]);

      data.map(r => {
        result1.push([r.date,Number(r.stock)]);
        result2.push([r.date,Number(r.price)]);
      })

      setChartData1(result1);
      setChartData2(result2);
  }

  return (
    <>
      {product && (
        <>
          <Title>{product.name}</Title>
          <SubTitle>
            {product.algorithm_name} [{product.url}]
          </SubTitle>

          <h2>Aktualna cena: {product.last_price}</h2>
          <h2>Aktualny stan: {product.last_stock}</h2>
          <br />

          <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={chartData1}
            options={options1}
          />

<Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={chartData2}
            options={options2}
          />

          {product.data && <Table data={product.data} headers={tableHeaders} />}
        </>
      )}
    </>
  );
};

export default ProductView;
