import React from "react";
import { Dashboard } from "Theme";
import { Route, Routes } from "react-router";
import * as Page from "Page";

const AuthenticatedApp = () => {
  return (
    <Dashboard>
      <Routes>
        <Route path="/" element={<Page.HomePage />} />
        <Route
          exact
          path="/product/index"
          element={<Page.ProductIndexPage />}
        />
        <Route exact path="/product/add" element={<Page.ProductAddPage />} />

        <Route exact path="/product/edit/:id" element={<Page.ProductEditPage />} />
        <Route exact path="/product/view/:id" element={<Page.ProductViewPage />} />
      </Routes>
    </Dashboard>
  );
};

export default AuthenticatedApp;
