import axios from 'axios';
// import { toast } from "react-toastify";
import { store } from '../_helpers/store';
import { userActions } from '../_actions';

var api = axios.create({
  baseURL: 'https://riku-api.codeverture.pl/front/',
  validateStatus: (status) => {
    return status < 500;
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    // console.error("API Error");
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      console.error('Authorization failed');

      store.dispatch(userActions.logout());

      return Promise.reject(response);
    } else if (response.status > 200) {
      if (typeof response.data.error !== 'undefined') {
        // toast.error(response.data.error);
      }

      return Promise.reject(response);
    }

    if (response.data.error) {
      return Promise.reject(response.data.error);
    }

    //toast.error("Test");

    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
