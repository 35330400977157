import React, { useState } from 'react';
import { Navbar, NavbarShortcut, Aside } from './index';
import { MdExitToApp } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import Menu from '_menu';

const Dashboard = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();
  const [isOpen, setOpen] = useState(false);
  const hide = () => setOpen(false);
  const toggleMenu = () => setOpen(!isOpen);
  return (
    <div>
      {location.pathname !== '/login' ? (
        <>
          <Navbar>
            <React.Fragment>
              <div className="flex items-center">
                <button className="mr-2 transition duration-300 ease-in-out transform  hover:-rotate-90 " aria-label="Open Menu" onClick={toggleMenu}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-8 h-8"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                </button>
                <span className="h-auto w-36 font-bold">Riku</span>
              </div>
           
            </React.Fragment>
          </Navbar>
          <Aside isOpen={isOpen}>
            <React.Fragment>
              <span onClick={() => setOpen(false)} className="flex w-full items-center p-4 border-b font-bold">
                Riku
              </span>

              <Menu hide={hide} />

              <div className="fixed bottom-0 w-full">
                <button onClick={auth.signOut} className="flex items-center p-4 text-white bg-blue-500 hover:bg-blue-600 w-full">
                  <div className="mr-2 text-lg">
                    <MdExitToApp />
                  </div>
                  <span>Wyloguj</span>
                </button>
              </div>
            </React.Fragment>
          </Aside>

          <section className="2xl:container mx-auto  p-5">{children}</section>

          <div
            style={{ width: '0px' }}
            enterclassname="opacity-0"
            enteractiveclassname="ease-out transition-medium"
            entertoclassname="opacity-100"
            leaveclassname="opacity-100"
            leaveactiveclassname="ease-out transition-medium"
            leavetoclassname="opacity-0"
          >
            {isOpen && (
              <div className="z-10 fixed inset-0 transition-opacity">
                <div onClick={() => setOpen(false)} className="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default Dashboard;
