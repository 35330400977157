import React from 'react';

const Title = ({ children, actions = null, withSubtitle = false }) => {
  return (
    <div className="flex justify-between">
      <h2 className={`mt-2 ${withSubtitle ? 'mb-2' : 'mb-6'} text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl`}>
        {children}
      </h2>
      {actions ? actions : null}
    </div>
  );
};

export default Title;
