import React from 'react';

const Table = ({ headers, data, key, actions }) => {
  return (
    <table className="items-center bg-transparent w-full border-collapse ">
      <thead>
        <tr>
          {headers.map(({ title, width = null }) => (
            <th
              key={title}
              scope="col"
              className="px-2 bg-blue-500 text-white align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0  font-semibold text-left"
              style={{ width: width ? `${width}px` : 'auto' }}
            >
              {title}
            </th>
          ))}

          {actions && <th></th>}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.length == 0 ? (
          <tr>
            <td colspan="99" className="border-t-0 px-6 align-center border-l-0 border-r-0 text-sm  p-4">
              Brak rekordów
            </td>
          </tr>
        ) : null}
        {data.map((d) => (
          <tr key={d[key]}>
            {headers.map((header) => (
              <td className="border-t-0 px-2 align-center border-l-0 border-r-0 text-sm  p-4">{header.render ? header.render(d) : d[header.prop]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
