import React from 'react';
import classNames from 'classnames';

const Select = React.forwardRef(({ items, beautiful, ...props }, ref) => {
  //text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500
  return (
    <select
      className={classNames({
        'w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500': beautiful,
        'shadow border rounded w-full py-2 px-3 text-grey-darker': !beautiful
      })} 
      {...props}
      ref={ref}
    >
      {items.map((i) => (
        <option value={i.id} key={i.id}>
          {i.name}
        </option>
      ))}
    </select>
  );
});

export default Select;
