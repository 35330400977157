import React from 'react';

const Input = React.forwardRef(({ value, type, placeholder, onChange, beautiful, ...props }, ref) => {
  //text-lg border-b border-gray-300 focus:outline-none focus:border-indigo-500
  return (
    <input
      className={`${
        beautiful
          ? 'w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500'
          : ' shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker'
      }`}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      ref={ref}
      {...props}
    />
  );
});

export default Input;
