import React from "react";
import { useForm } from "react-hook-form";
import { TextField } from "Theme";
import { useAuth } from "hooks/useAuth";
import { GrPlan } from "react-icons/gr";

const LoginPage = () => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <div></div>
            <div className="text-2xl text-indigo-800 tracking-wide ml-2 font-semibold">
              Riku
            </div>
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-indigo-900 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Logowanie
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit(auth.signIn)}>
             
              <div>
                <TextField
                  caption="Login"
                  name="login"
                  id="login"
              
                  beautiful={true}
                  {...register("login", { required: true })}
                />
                {errors.login && <span>Login is required</span>}
              </div>
              <div className="mt-8">
                <TextField
                  caption="Hasło"
                  name="password"
              
                  id="password"
                  {...register("password", { required: true })}
                  beautiful={true}
                  type="password"
                />
              </div>
              <div className="mt-10">
                <button
                  className="bg-indigo-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                >
                  Zaloguj
                </button>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
        <div
          className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer"
          style={{ fontSize: 256 }}
        >
          <GrPlan />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
