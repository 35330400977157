import api from "./api";
import { response } from "./response";

export const algorithmService = {
  index,
};

function index() {
  return api
    .get("/algorithm/index")
    .then(response)
    .then((data) => {
        console.log("index");
        console.log(data)
      return data;
    })
    .catch((error) => {

      return Promise.reject(error);
    });
}
