import React from 'react';
import { MdWarning } from 'react-icons/md';

const Error = ({ error }) => (
  <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
    <div slot="avatar">
      <MdWarning />
    </div>
    <div className="font-normal  max-w-full flex-initial">{error}</div>
    <div className="flex flex-auto flex-row-reverse">
      <div></div>
    </div>
  </div>
);

export default Error;
