import React, { useEffect, useState } from 'react';
import { Button, SelectField, TextField } from 'Theme';
import { productService as api, algorithmService } from '_services';

const initState = {
  name: '',
  algorithm: 0,
  url: ''
};



const ProductAdd = ({ refresh }) => {

  const [formFields, setFormFields] = useState(initState);
  const [algorithms, setAlgorithms ] = useState([]);
  const onChange = ({ target: { value, name } }) =>
    setFormFields({
      ...formFields,
      [name]: value,
    });

  useEffect(() => {
   
  
    algorithmService.index().then(data => {
     setAlgorithms(data);
    } )
  }, []);

  const addProduct = (e) => {
    e.preventDefault();
    if(!formFields.name) {
      alert("Podaj nazwe produktu");
      return;
    }

    if(!formFields.url) {
      alert("Podaj url");
      return;
    }

    if(formFields.algorithm=='0') {
      alert("Wybierz mechanizm");
      return;
    }
    api.create(formFields).then((data) => {
      setFormFields(initState);
    }).catch(() => {
      alert("Nie udało się dodać");
    })
  };

  return (
    <React.Fragment>
      <form onSubmit={addProduct}>
        <TextField caption="Nazwa produktu" name="name" value={formFields.name} onChange={onChange} error={null} />
        <SelectField caption="Mechanizm" name="algorithm" items={[{id: 0, name: "Wybierz"} ,...algorithms]} value={formFields.algorithm} onChange={onChange} error={null} />
        <TextField caption="Url" name="url" value={formFields.url} onChange={onChange} error={null} />
        <Button type="submit"> Utwórz</Button>
      </form>
    </React.Fragment>
  );
};

export default ProductAdd;
