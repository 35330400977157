import React from 'react';

const Label = ({ children, htmlFor }) => {
  return (
    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default Label;
