import React, { useContext, useEffect, useState } from 'react';
import { useError } from 'hooks/useError';
import { userService as api } from '_services';
const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { dispatchError } = useError();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('token');
   
   
    if (token) {
      (async () => {
        try {
          const response = await api.me(token);
          setUser(response);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, []);

  const signIn = async ({ login, password }) => {
    try {
      const response = await api.login(login, password);
      //alert(response.token);
      setUser(response);
      localStorage.setItem('token', response.token);
    } catch (e) {
      dispatchError('Niepoprawne dane logowania');
    }
  };

  const signOut = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  const haveAccess = (module) => {
    if (user) {
      let o = user.modules.find((m) => m.name === module);

      if (o) {
        return Number(o.power);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return <AuthContext.Provider value={{ user, signIn, signOut, haveAccess, isLoading }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw Error('useAuth needs to be used inside AuthContext');
  }

  return auth;
};

