import React from 'react';
import { Select, Label } from '../../../Theme';

const SelectField = React.forwardRef(({ caption, items, beautiful = false, ...props }, ref) => {
  return (
    <div className="mb-4">
      <Label>{caption}</Label>
      <Select items={items} beautiful={beautiful} ref={ref} {...props} />
    </div>
  );
});

export default SelectField;
