import api from "./api";
import { response } from "./response";

export const productService = {
  index,
  get,
  create,
  update,
  remove,
  refresh
};

function index() {
  return api
    .get("/product/index")
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("API ERROR");
      console.error(error);
      return Promise.reject(error);
    });
}

function get(id) {
  return api
    .get(`/product/get/${id}`)
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("API ERROR");
      console.error(error);
      return Promise.reject(error);
    });
}


function create(product) {
  return api
    .post("/product/create",product)
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function update(id,product) {
  return api
    .post(`/product/update/${id}`,product)
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}


function remove(id) {
  return api
    .post("/product/remove",{id: id})
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function refresh(id) {
  return api
    .post("/product/refresh",{id: id})
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

