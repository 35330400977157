import React from "react";
import { MenuItem } from "Theme";
import { Route, Routes } from "react-router-dom";
import {
  MdAttachMoney,
  MdLink,
  MdBuild,
  MdKeyboardArrowLeft,
  MdDashboard,
  MdSettings,
  MdPeople,
  MdPerson,
  MdReorder,
  MdEvent,
} from "react-icons/md";
import { useAuth } from "hooks/useAuth";

const Menu = ({ hide }) => {
  const auth = useAuth();
  return (
    <> 
            <MenuItem
              to="/"
              title="Pulpit"
              callback={hide}
              icon={<MdDashboard />}
            />
            <MenuItem
              to="/product/index"
              title="Produkty"
              callback={hide}
              icon={<MdEvent />}
            />

            <MenuItem
              to="/product/add"
              title="Dodaj produkt"
              callback={hide}
              icon={<MdEvent />}
            />
</>     
     
  );
};

export default Menu;
