import api from './api';
import { response } from './response';

export const userService = {
  getUserData,
  me,
  login,
  logout,
  getAll,
  create,
};

function create(user) {
  return api
    .post('/user/create', JSON.stringify(user))
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('API ERROR');
      console.error(error);
      return Promise.reject(error);
    });
}

function getUserData(id) {
  return api
    .get('/user/getuserdata/' + id)
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('API ERROR');
      console.error(error);
      return Promise.reject(error);
    });
}

function me() {
  return api
    .get('/user/me')
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('API ERROR');
      console.error(error);
      return Promise.reject(error);
    });
}

function login(login, password) {
  return api
    .post(`/auth/login`, JSON.stringify({ login, password }))
    .then(response)
    .then(({ user }) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //localStorage.setItem('user', JSON.stringify(user));

      return user;
      //return Promise.resolve(userToken);
    })
    .catch((data) => {
      return Promise.reject(data);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function getAll() {
  return api
    .get(`/user/index`)
    .then(response)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('API ERROR');
      console.error(error);
      return Promise.reject(error);
    });
}
