import React from 'react';

const Navbar = ({ isOpen, children }) => (
  <aside
    className={`${
      isOpen ? 'translate-x-0' : '-translate-x-full'
    } transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30`}
  >
    {children}
  </aside>
);

export default Navbar;
