export function response(response) {
  if (response.status == 200) {
    const data = response.data;

    if (data.status == 401) {
      const error = 'Nieudane logowanie';
      return Promise.reject(error);
    }

    if (data.error) {
 
      return Promise.reject(data.error);
    }

    return Promise.resolve(data);
  } else {
    const data = response.data;
    console.log(data);
    const error = (data && data.message) || response.statusText;

    return Promise.reject(error);
  }
}
