import React from 'react';
import { Input, Label, Error } from 'Theme';

const TextField = React.forwardRef(
  ({ error = null, hidden = false, caption, value, name, id, placeholder, onChange, type = 'text', beautiful = false, ...props }, ref) => {
    return (
      <div className={`mb-4 ${hidden?'hidden':''}`}>
        <Label htmlFor={id}>{caption}</Label>
        <Input
          name={name}
          id={id}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          data-testid={caption}
          {...props}
          ref={ref}
          beautiful={beautiful}
        />
        {error ? <Error error={error} /> : null}
      </div>
    );
  }
);

export default TextField;
