import React from 'react';


const HomePage = () => {
  return (
    <React.Fragment>
      <h1>Home</h1>
    </React.Fragment>
  );
};

export default HomePage;
