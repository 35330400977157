import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ to, title, callback, icon }) => (
  <Link to={to}>
    <span onClick={callback} className="flex items-center p-4 hover:bg-blue-500 hover:text-white ">
      <div className="mr-2 text-lg">{icon}</div>
      <span>{title}</span>
    </span>
  </Link>
);

export default MenuItem;
