import React from 'react';
import { useAuth } from 'hooks/useAuth';
import ErrorMessage from '_components/ErrorMessage/ErrorMessage';
import { useError } from 'hooks/useError';
import AuthenticatedApp from 'views/AuthenticatedApp';
import UnauthenticatedApp from 'views/UnauthenticatedApp';
import Loader from './Loader';

const Root = () => {
  const auth = useAuth();
  const { error } = useError();

  return (
    <>
      {error ? <ErrorMessage message={error} /> : null}
      {auth.isLoading ? <Loader /> : <>{auth.user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</>}
    </>
  );
};

export default Root;
