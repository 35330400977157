import React from 'react';

const Loader = () => (
  <div className="flex h-screen">
    <div className="m-auto">
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    </div>
  </div>
);

export default Loader;
